import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Assets
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

//Styles
import "../../../static/styles/App.css";
import { makeStyles } from "@mui/styles";
import { Button, Typography, LinearProgress, Dialog } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";

//Components
import ReferralModal from "../Courses/ReferralModal";
import {
    StyledBlackButton,
} from "../Styled Components/Buttons";
import Refer from "../../la_county/Refer";
import { CustomDialogTitle } from "../Styled Components/DialogTitle";
import CCCEEarthDayReferDialog from "../../3ce/CampaignComponents/2022-3ce-earthday/Refer/ReferDialog";
import LACEarthDayReferDialog from "../../la_county/CampaignComponents/2022-lac-earthday/Refer/ReferDialog";
import LACIndependenceReferDialog from "../../la_county/CampaignComponents/2022-lac-independence/Refer/ReferDialog";
import CCCEIndependenceReferDialog from "../../3ce/CampaignComponents/2022-3ce-independence/Refer/ReferDialog";
import LACRideshareReferDialog from "../../la_county/CampaignComponents/2022-lac-rideshare/Refer/ReferDialog";
import EarthDayReferDialog from "../../la_county/CampaignComponents/2023-lac-earthday/Refer/ReferDialog";
import LAC2023RideshareReferDialog from "../../la_county/CampaignComponents/2023-lac-rideshare/Refer/ReferDialog";
import LACEarthDay2024ReferDialog from "../../la_county/CampaignComponents/2024-lac-earthday/Refer/ReferDialog";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    progressGridItem: {
        marginBottom: 55,
        [theme.breakpoints.down('lg')]: {
            minWidth: "100%",
        },
    },
    pointsGridItem: {
        marginLeft: 15,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginTop: 15,
            minWidth: "100%",
        },
    },
    referGridItem: {
        height: "100%",
        marginLeft: 10,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginTop: 15,
            marginBottom: 15,
        },
    },
    completionPercentageText: {
        fontWeight: 600,
        paddingTop: 4,
        [theme.breakpoints.down('lg')]: {
            fontSize: ".75rem",
            paddingTop: 6,
        },
    },
    barColor: {
        backgroundColor: "rgba(255, 213, 0, 1)",
    },
    bar: {
        borderRadius: 15,
    },
    dialogPaper: {
        [theme.breakpoints.down('lg')]: {
            margin: 16,
        },
    },
    dialogContent: {
        paddingLeft: 30,
        paddingRight: 30,
        [theme.breakpoints.down('xl')]: {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
}));

function LearningProgressGrid(props) {
    const classes = useStyles();

    const {
        courseMetrics,
        handleClick, // No longer seems to be used
        handleClickAway, // No longer seems to be used
        courseInfo,
        courseID,
        userProfile,
    } = props;
    const [visible, toggleModal] = useState(false);

    const openReferralModal = () => {
        toggleModal(true);
    };

    return (
        <Grid container alignItems="center" className={classes.gridContainer}>
            <Grid item xs className={classes.progressGridItem}>
                <Typography
                    variant="h5"
                    style={{ fontWeight: 600, marginBottom: 4 }}
                >
                    Learning Progress
                </Typography>
                <div style={{ position: "relative", width: "100%" }}>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={
                                courseMetrics.completedTasksPercentage < 9
                                    ? 9
                                    : courseMetrics.completedTasksPercentage
                            }
                            style={{
                                height: 30,
                                borderRadius: 15,
                                backgroundColor: "lightgray",
                            }}
                            classes={{
                                barColorPrimary: classes.barColor,
                                bar: classes.bar,
                            }}
                        />
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            zIndex: 1,
                            /* left: courseMetrics.completedTasksPercentage < 7 
                                    ? 8
                                    : courseMetrics.completedTasksPercentage - 8 + "%" */
                            right:
                                courseMetrics.completedTasksPercentage < 9
                                    ? "93%"
                                    : 102 -
                                      courseMetrics.completedTasksPercentage +
                                      "%",
                        }}
                    >
                        <Typography
                            variant="body1"
                            className={classes.completionPercentageText}
                        >
                            {courseMetrics.completedTasksPercentage}%
                        </Typography>
                    </div>
                    <Typography
                        variant="caption"
                        style={{
                            position: "absolute",
                            top: 35,
                            fontStyle: "italic",
                        }}
                    >
                        Your progress is saved
                    </Typography>
                </div>
            </Grid>

            <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="center"
                className={classes.pointsGridItem}
            >
                <Grid item>
                    <CardGiftcardIcon style={{ height: 60, width: 60 }} />
                </Grid>
                <Grid item xs>
                    <Typography variant="h6" style={{ lineHeight: "normal" }}>
                        {Math.round(courseMetrics.completedPointsCounter)}{" "}
                        points earned
                    </Typography>
                    <Typography variant="caption">
                        out of {courseMetrics.totalPointsCounter} total
                    </Typography>
                </Grid>
            </Grid>

            {/* <Grid item container alignItems="center" justify="center" style={{width: 'auto', marginLeft: 15}}>
                <FontAwesomeIcon icon={faAward} color={courseMetrics.bonusMultiplier > 1 ? "#2ab151" : "#c0c0c0"} style={{height: 60, width: 60}} />
            </Grid>
            <Grid item container alignItems="center" justify="center" style={{height: '100%', width: 'auto'}}>
                <Button id="bonusButton" onMouseOver={handleClick} onMouseLeave={handleClickAway} style={{textTransform: 'none', display: 'block', textAlign: 'left'}}>
                    <Typography variant="h6" style={{fontWeight: 700}}>
                        +{Math.round((courseMetrics.bonusMultiplier - 1) * 100)}%
                    </Typography>
                    <FontAwesomeIcon icon={faInfoCircle} color="#c0c0c0" style={{height: 15, width: 15}} />
                </Button>
            </Grid> */}

            {courseInfo.evLearningCourse && (
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginLeft: 15, width: "auto" }}
                >
                    <FontAwesomeIcon
                        icon={faHandsHelping}
                        style={{ height: 60, width: 60 }}
                    />
                </Grid>
            )}

            {courseInfo.evLearningCourse ? (
                <Grid
                    item
                    alignItems="center"
                    className={classes.referGridItem}
                >
                    <Link to="/refer" style={{ textDecoration: "none" }}>
                        {/* <Button id="referralButton" onMouseOver={handleClick} onMouseLeave={handleClickAway} style={{textTransform: 'none', display: 'block', textAlign: 'left'}}> */}
                        <Button
                            id="referralButton"
                            style={{
                                textTransform: "none",
                                display: "block",
                                textAlign: "left",
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{ lineHeight: "normal" }}
                            >
                                Refer a friend
                            </Typography>
                            <Typography variant="caption">
                                Get more chances to win
                            </Typography>
                            {/* <FontAwesomeIcon icon={faInfoCircle} color="#c0c0c0" style={{height: 15, width: 15, marginLeft: 10}} /> */}
                        </Button>
                    </Link>
                </Grid>
            ) : null 
            }

            
            { 
                // Hides the referral button for specified courses
                courseID !== "pjaCg8xiMHNxhmAsdjla" ? // Electric Cars for Earth - Demo, for 3CE Sample
                (
                    <Grid item className={classes.referGridItem}>
                        <div>
                            <StyledBlackButton onClick={openReferralModal}>
                                REFER A FRIEND
                            </StyledBlackButton>
                            <br />
                            <Typography
                                variant="body1"
                                style={{ textAlign: "center", marginTop: 3 }}
                            >
                                Get more chances to win
                            </Typography>
                        </div>
                    </Grid>
                )
            : null
            }


            {   // SHOW THE ACTUAL REFERRALS DIALOG UPON BUTTON CLICK
                // THIS CODEBLOCK IS LEGACY CODE... Instead, follow the example below for 2022-XXX-earthday campaigns
            courseID === "AFRX5MaCGKb4hpqlxH4G" || 
            courseID === "BScQ6meMbXZlXkSALuOz" ||
            courseID === "vBPrFdhWsSfA4JjhTvEw" ? (
                <Dialog
                    onClose={() => toggleModal(false)}
                    open={visible}
                    maxWidth="md"
                    classes={{ paper: classes.dialogPaper }}
                >
                    <CustomDialogTitle onClose={() => toggleModal(false)} />
                    <div className={classes.dialogContent}>
                        <Refer email={userProfile.data.email} />
                    </div>
                </Dialog>
            ) : (
                null
                // <ReferralModal
                //     visible={visible}
                //     handleClose={() => toggleModal(false)}
                // />
            )}

            { courseID === "POK19FUOtIkmyom8wKxm" && ( // 2024-lac-earthday
                <LACEarthDay2024ReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
            { courseID === "AiPIJP02tjNPBOoQliyr" && ( // 2023-lac-earthday
                <LAC2023RideshareReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
            { courseID === "AS4SRYKfo2M0vaEsafRq" && ( // 2023-lac-earthday
                <EarthDayReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
            { courseID === "W2Si2qJRCicGm4VuzXaX" && ( // 2022-lac-rideshare
                <LACRideshareReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
            { courseID === "0DdJkIK073oVR6Y8iEN0" && ( // 2022-3ce-independence
                <CCCEIndependenceReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
            { courseID === "ZLlUj8l82hFEO4wHkvoN" && ( // 2022-lac-independence
                <LACIndependenceReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}

            { courseID === "SMjr20wuo516hlhvJvKv" && ( // 2022-3ce-earthday
                <CCCEEarthDayReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}

            { courseID === "LBAih7yvjrI6tkCRCERJ" && ( // 2022-lac-earthday
                <LACEarthDayReferDialog 
                    open={visible}
                    toggleDialog={() => toggleModal(!visible)}
                    email={userProfile.data.email}
                />
            )}
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LearningProgressGrid)
);
