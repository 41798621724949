const campaignCourses = {
    rideshare_fair: {
        name: "Rideshare Fair",
        course: "PgbSH9lk2GLlHCHvegJd",
        sub_sections: {},
    },
    drive_electric_week: {
        name: "Drive Electric Week",
        course: "7YgbfBAHTR8PYLM4Q4wk",
        sub_sections: {
            WyqVaS1xoN7IOc9CTAq3: {
                tasks: 3,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            eGPjfoTEKU12u5W1lNss: {
                tasks: 5,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            GcWDp7nXfxXrU9R9a4Vb: {
                tasks: 5,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            EEfL99oALjvU8dl16PE9: {
                tasks: 2,
                completion_bonus: 20,
                completed: 0,
            },
            pGHry5r1XsH5Q7wxUl4f: {
                tasks: 2,
                completion_bonus: 20,
                completed: 0,
            },
        },
    },
    earth_day: {
        name: "Earth Day",
        course: "AFRX5MaCGKb4hpqlxH4G",
        sub_sections: {
            m4sG8wwOra5XvBgOAdf5: {
                tasks: 2,
                completion_bonus: 10,
                completed: 0,
            },
            zE13RjCOBqSgfsIR3iYU: {
                tasks: 5,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            KFpctr07UWJcPHIGDPLl: {
                tasks: 3,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            hTdcVoLCxu0V4IgHRluC: {
                tasks: 4,
                completion_bonus: 5,
                completed: 0,
                quiz: true,
            },
            kLeBwct9IT19hyf8pr8z: {
                tasks: 1,
                completion_bonus: 15,
                completed: 0,
            },
            iuqz8oTU4jOiwJMdcaLr: {
                tasks: 2,
                completion_bonus: 15,
                completed: 0,
            },
        },
    },
    "2021-independence": {
        name: "2021 Independence Day",
        course: "BScQ6meMbXZlXkSALuOz",
        sub_sections: {
            "6VGbXjBDqMZohYFgG914": {
                tasks: 2,
                completion_bonus: 20,
                completed: 0,
            },
            LZPHzpeH66fMzFQQZvb3: {
                tasks: 4,
                completion_bonus: 20,
                completed: 0,
            },
            eYqdTtnmE0XKPStFB98Q: {
                tasks: 5,
                completion_bonus: 20,
                completed: 0,
                quiz_bonus: 10,
            },
            pxD2jATTfYYhqY8CE2rB: {
                tasks: 3,
                completion_bonus: 10,
                completed: 0,
            },
            PdToD70BlevTOqyf4Wdh: {
                tasks: 2,
                completion_bonus: 10,
                completed: 0,
            },
            "1nSHm8xFuLRQtSOyHZAY": {
                tasks: 1,
                completion_bonus: 10,
                completed: 0,
            },
        },
    },
    "2021-rideshare": {
        name: "2021 Rideshare Fair",
        course: "vBPrFdhWsSfA4JjhTvEw",
        sub_sections: {
            n2Vqq2maK1Drv0R9AcuV: {
                tasks: 2,
                completion_bonus: 0,
                completed: 0,
            },
            z18QCxUZS8YzLNde2gQq: {
                tasks: 4,
                completion_bonus: 0,
                completed: 0,
            },
            BAkD65qBOx6NXk6pkoTq: {
                tasks: 5,
                completion_bonus: 0,
                completed: 0,
            },
            fVxynAou9CXYVXGq9Kz1: {
                tasks: 3,
                completion_bonus: 0,
                completed: 0,
            },
            SaIblt4b8DmQc8VDzMgy: {
                tasks: 2,
                completion_bonus: 0,
                completed: 0,
            },
        },
    },
    "2022-3ce-earthday": {
        name: "2022-3ce-earthday",
        course: "SMjr20wuo516hlhvJvKv",
        sub_sections: {
            O5D9dLP4qKkDGNYQrCL6: {
                // 1.1 Welcome to Electric Cars for Earth! [2022-3ce-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            hAE3SdPCGv2IgBuk7JsL: {
                // 2.1 An Intro to Electric Cars [2022-3ce-earthday]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            "1UJFu5ZvwKmQR6ovycJb": {
                // 2.2 Save Money on Your Next Car [2022-3ce-earthday]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            xCzTaSZEyQnrqsIAzfvd: {
                // 3.1 Creating a Fossil Fuel Free Community [2022-3ce-earthday]
                tasks: 2,
                completion_bonus: 20, // Update this
                completed: 0, // Update this
            },
        },
    },
    "2022-lac-earthday": {
        name: "2022-lac-earthday",
        course: "LBAih7yvjrI6tkCRCERJ",
        sub_sections: {
            nDS851FWcVDMcanDA2RB: {
                // 1.1 Welcome to Electric Cars for Earth! [2022-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            "8qhANqisuQ40ahH4KLPA": {
                // 2.1 An Intro to Electric Cars [2022-lac-earthday]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            NdPkpz5A7JxdUxXpIguX: {
                // 2.2 Save Money on Your Next Car [2022-lac-earthday]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            "9Te6SfHWkZ20XLlac55M": {
                // 3.1 Creating a Fossil Fuel Free Community [2022-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
        },
    },
    "2022-lac-independence": {
        name: "2022-lac-independence",
        course: "ZLlUj8l82hFEO4wHkvoN",
        sub_sections: {
            CJpiRRHTYYytAyCjzdMF: {
                // 1.1 Welcome to Independence from Fossil Fuels! [2022-lac-independence]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            JCdXAHA2UyyPUkyek8TN: {
                // 2.1 Declare Your Independence [2022-lac-independence]
                tasks: 4,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            "4nZHjFssbNet9A0PJT30": {
                // 2.2 An Intro to Electric Cars [2022-lac-independence]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            jeQeJrgw2pisECNzZEl5: {
                // 3.1 Save Money on Your Next Car [2022-lac-independence]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            S3Qey7FrcH2jDJHyUTTG: {
                // 4.1 We’re Creating a Fossil Fuel Free Community [2022-lac-independence]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
        },
    },
    "2022-3ce-independence": {
        name: "2022-3ce-independence",
        course: "0DdJkIK073oVR6Y8iEN0",
        sub_sections: {
            "19ggDBPF5noWeGnSycQJ": {
                // 1.1 Welcome to Independence from Fossil Fuels! [2022-3ce-independence]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            "3gmjbT88y2qkTfACPpzq": {
                // 2.1 Declare Your Independence [2022-3ce-independence]
                tasks: 4,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            Ui6LIkr8IsKq3nbgoynn: {
                // 2.2 An Intro to Electric Cars [2022-3ce-independence]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            rXL3YcYkqX6UtT0wciRi: {
                // 3.1 Save Money on Your Next Car [2022-3ce-independence]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            "7wgWlcSTJ7mD7Nv6KWp5": {
                // 4.1 We're Creating a Fossil Fuel Free Community [2022-3ce-independence]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
        },
    },
    "2022-lac-rideshare": {
        name: "2022-lac-rideshare",
        course: "W2Si2qJRCicGm4VuzXaX",
        sub_sections: {
            eF6UWg9EvnsBdw1fS666: {
                // 1.1 Creating a Fossil Fuel Free LA [2022-lac-rideshare]
                tasks: 2,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            RfKACMrWtJa7s7wATIw0: {
                // 2.1 Ridesharing to Work [2022-lac-rideshare]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            e4MRpXT5Py1bXTjMh5QW: {
                // 3.1 Understanding the Basics of Electric Cars [2022-lac-rideshare]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            "2IFAXvvETMV1hYjMtqlN": {
                // 3.2 Going Electric Has Never Been Easier [2022-lac-rideshare]
                tasks: 3,
                completion_bonus: 10,
                completed: 0, // Update this
                // quiz: true,
            },
            fetCLMfuxmjj5pnpshkU: {
                // 4.1 We're Creating a Fossil Fuel Free Community [2022-3ce-independence]
                tasks: 1,
                completion_bonus: 10,
                completed: 0, // Update this
            },
        },
    },
    "2023-lac-earthday": {
        name: "2023-lac-earthday",
        course: "AS4SRYKfo2M0vaEsafRq",
        sub_sections: {
            Ymg2r6cpkTZWwWSOEW2t: {
                // 1.1 Welcome to Electric Cars for Earth! [2023-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            PEGXVvkR4cv2fgBxArdL: {
                // 2.1 An Intro to Electric Cars [2023-lac-earthday]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            U56TuTGLbkotPc3W53yx: {
                // 2.2 Save Money on Your Next Car [2023-lac-earthday]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            FBZoWYAJkb6lNFmvGmIr: {
                // 3.1 Creating a Fossil Fuel Free Community [2023-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
        },
    },
    "2023-lac-rideshare": {
        name: "2023-lac-rideshare",
        course: "AiPIJP02tjNPBOoQliyr",
        sub_sections: {
            zRSW0tSUlcXLf8ipbzd0: {
                // 1.1 Creating a Fossil Fuel Free LA [2023-lac-rideshare]
                tasks: 2,
                completion_bonus: 10,
                completed: 0, // Update this
            },
            "3wUMxiAJh5SZE09gAwYe": {
                // 2.1 Ridesharing to Work [2023-lac-rideshare]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            "0sY2eH3vHrpRV8rkEe3S": {
                // 3.1 Understanding the Basics of Electric Cars [2023-lac-rideshare]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            ygEpZNb6w4ilZ4aCkDYc: {
                // 3.2 Going Electric Has Never Been Easier [2023-lac-rideshare]
                tasks: 3,
                completion_bonus: 10,
                completed: 0, // Update this
                // quiz: true,
            },
            ziyHlpLRsQ2DQ4KI61UW: {
                // 4.1 Share Your Thoughts on the Clean Air Rideshare Fair [2023-lac-rideshare]
                tasks: 1,
                completion_bonus: 10,
                completed: 0, // Update this
            },
        },
    },
    "2024-lac-earthday": {
        name: "2024-lac-earthday",
        course: "POK19FUOtIkmyom8wKxm",
        sub_sections: {
            P0e68NciJgOqrfUO9Me5: {
                // 1.1 Welcome to Electric Cars for Earth! [2024-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
            HT6hkC9ELXwBr8qNlOcU: {
                // 2.1 An Intro to Electric Cars [2024-lac-earthday]
                tasks: 5,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            dbcl1ICW4VUvzcjuSzJ5: {
                // 2.2 Save Money on Your Next Car [2024-lac-earthday]
                tasks: 4,
                completion_bonus: 10,
                completed: 0, // Update this
                quiz: true,
            },
            iEGtNFsa9pLf5ZeUZWNV: {
                // 3.1 Creating a Fossil Fuel Free Community [2024-lac-earthday]
                tasks: 2,
                completion_bonus: 20,
                completed: 0, // Update this
            },
        },
    },
};

export const downloadUserCSV = (users, selectedCampaign, campaigns) => {
    const campaignData = campaigns.filter(
        (item) => item.value === selectedCampaign
    )[0];

    let rows;
    if (selectedCampaign) {
        rows = [
            [
                "First name",
                "Last name",
                "Email",
                "Organization",
                // LA County fields
                "LA County Employee",
                "LA County Resident",
                "Employee ID",
                "Department",
                "Worksite Address",
                "Zip Code",
                // End fields
                "Saved Cars",
                "Referred By (Registration)",
                "Referrals Count (Registration)",
                "Total Completed Tasks",
                "Total Earned Points",
                "Date Created",
                // Campaign Items
                "Referrals Activity Response",
                campaignData.name + " Referred By",
                campaignData.name + " Referrals Count",
                campaignData.name + " Completed Tasks",
                campaignData.name + " Earned Points",
                campaignData.name + " Bonus Points",
            ],
        ];
    } else {
        rows = [
            [
                "First name",
                "Last name",
                "Email",
                "Organization",
                // LA County fields
                "LA County Employee",
                "LA County Resident",
                "Employee ID",
                "Department",
                "Worksite Address",
                "Zip Code",
                // End fields
                "Saved Cars",
                "Referred By (Registration)",
                "Referrals Count (Registration)",
                "Total Completed Tasks",
                "Total Earned Points",
                "Date Created",
            ],
        ];
    }

    for (let i in users.filteredData) {
        let user = users.filteredData[i];
        let completed_campaign_tasks = 0;
        let campaign_points = 0;
        let campaign_bonus_points = 0;
        let referralsActivityResponse = undefined;

        if (selectedCampaign) {
            for (let prop in user.completed_tasks) {
                const task = user.completed_tasks[prop];
                if (task.course_id === campaignData.course) {
                    completed_campaign_tasks++;
                    campaign_points += user.completed_tasks[prop].points;
                }

                const sub_section_id = task.sub_section_id;
                const currentSubsection =
                    campaignCourses[selectedCampaign].sub_sections[
                        sub_section_id
                    ];
                if (
                    task.type === "quiz" &&
                    task.points === 30 &&
                    currentSubsection
                ) {
                    if (currentSubsection.quiz) {
                        campaign_bonus_points += 15;
                    } else if (currentSubsection.quiz_bonus) {
                        campaign_bonus_points += currentSubsection.quiz_bonus;
                    }
                }

                if (currentSubsection) {
                    currentSubsection.completed += 1;
                }

                if (
                    selectedCampaign === "2021-independence" &&
                    prop === "ZQLsoE485xIxhsoBtKUT"
                ) {
                    if (task.points === 5) {
                        referralsActivityResponse = "No";
                    } else {
                        referralsActivityResponse = "Yes";
                    }
                }

                if (
                    selectedCampaign === "2021-rideshare" &&
                    prop === "vRlOjNgpGWKtxQa4wVJl"
                ) {
                    if (task.points === 10) {
                        referralsActivityResponse = "No";
                    } else {
                        referralsActivityResponse = "Yes";
                    }
                }
            }

            for (let prop in campaignData.sub_sections) {
                const sub_section = campaignData.sub_sections[prop];

                if (sub_section.tasks === sub_section.completed) {
                    campaign_bonus_points += sub_section.completion_bonus;
                }

                campaignData.sub_sections[prop].completed = 0;
            }
        }

        let savedCars = "";
        if (user.saved_cars) {
            for (let i in user.saved_cars) {
                const car = user.saved_cars[i];
                const carName = `${car["Model Year"]} ${car.Make} ${car.Model}`;
                savedCars += carName + "; ";
            }
        }

        if (selectedCampaign) {
            rows.push([
                user.first_name,
                user.last_name,
                user.email,
                user.organization.name || user.organization,
                // LA County fields
                user.organization && user.organization.employee,
                user.organization && user.organization.la_resident,
                (user.organization && user.organization.employee_id) ||
                    user.employee_id,
                (user.organization &&
                    user.organization.department &&
                    user.organization.department.replace(/,/g, " ")) ||
                    (user.department && user.department.replace(/,/g, " ")),
                (user.organization &&
                    user.organization.worksite &&
                    user.organization.worksite.replace(/,/g, " ")) ||
                    (user.worksite && user.worksite.replace(/,/g, " ")),
                (user.organization &&
                    user.organization.zipcode &&
                    user.organization.zipcode.replace(/,/g, " ")) ||
                    (user.zipcode && user.zipcode.replace(/,/g, " ")),
                // End fields
                savedCars,
                user.referred_by && user.referred_by.registration
                    ? user.referred_by.registration.replace(/,/g, "/")
                    : undefined,
                user.referrals_count && user.referrals_count.registration,
                user.completed_tasks_count,
                user.points,
                user.date_created ? user.date_created.toDate() : undefined,
                // Campaign Items
                referralsActivityResponse,
                user.referred_by && user.referred_by[selectedCampaign]
                    ? user.referred_by[selectedCampaign].replace(/,/g, "/")
                    : undefined,
                user.referralsCount && user.referralsCount[selectedCampaign],
                completed_campaign_tasks,
                campaign_points,
                campaign_bonus_points,
            ]);
        } else {
            rows.push([
                user.first_name,
                user.last_name,
                user.email,
                user.organization.name || user.organization,
                // LA County fields
                user.organization && user.organization.employee,
                user.organization && user.organization.la_resident,
                (user.organization && user.organization.employee_id) ||
                    user.employee_id,
                (user.organization &&
                    user.organization.department &&
                    user.organization.department.replace(/,/g, " ")) ||
                    (user.department && user.department.replace(/,/g, " ")),
                (user.organization &&
                    user.organization.worksite &&
                    user.organization.worksite.replace(/,/g, " ")) ||
                    (user.worksite && user.worksite.replace(/,/g, " ")),
                (user.organization &&
                    user.organization.zipcode &&
                    user.organization.zipcode.replace(/,/g, " ")) ||
                    (user.zipcode && user.zipcode.replace(/,/g, " ")),
                // End fields
                savedCars,
                user.referred_by && user.referred_by.registration
                    ? user.referred_by.registration.replace(/,/g, "/")
                    : undefined,
                user.referrals_count && user.referrals_count.registration,
                user.completed_tasks_count,
                user.points,
                user.date_created ? user.date_created.toDate() : undefined,
            ]);
        }
    }

    var csvContent = "";

    rows.forEach(function (rowArray) {
        var row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var download = function (content, fileName, mimeType) {
        var a = document.createElement("a");
        mimeType = mimeType || "application/octet-stream";

        if (navigator.msSaveBlob) {
            // IE10
            navigator.msSaveBlob(
                new Blob([content], {
                    type: mimeType,
                }),
                fileName
            );
        } else if (URL && "download" in a) {
            //html5 A[download]
            a.href = URL.createObjectURL(
                new Blob([content], {
                    type: mimeType,
                })
            );
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            window.location.href =
                "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
        }
    };

    download(
        csvContent,
        "Electrifyze User Export.csv",
        "text/csv;encoding:utf-8"
    );
};
