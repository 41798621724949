import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Routing
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    withRouter,
} from "react-router-dom";

//Components
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import AuthenticatedHome from "./pages/AuthenticatedHome";
import AuthenticatedAbout from "./pages/AuthenticatedAbout";
import AuthenticatedCampaign from "./pages/AuthenticatedCampaign";
import Campaign from "./pages/Campaign";
import CampaignLaunch from "./pages/CampaignLaunch";
import Coaching from "./pages/Coaching";
import ChooseEV from "./pages/ChooseEV";
import Contact from "./pages/Contact";
import Contests from "./pages/Contests";
import Courses from "./pages/Courses";
import Dashboard from "./pages/Dashboard";
import LearnAboutEV from "./pages/LearnAboutEV";
import Login from "./pages/Login";
import Refer from "./pages/Refer";
import Sections from "./pages/Sections";
import SurveyComplete from "./pages/SurveyComplete";
import Task from "./pages/Task";
import Transition from "./pages/Transition";
import PasswordReset from "./pages/PasswordReset";
import PastCampaigns from "./pages/PastCampaigns";
import PreRegister from "./pages/PreRegister";

//Components
import Header from "./components/default/Header/Header";
import PrivateRoute from "./components/default/Routes/PrivateRoute";
import AlertDialog from "./components/default/Styled Components/AlertDialog";
import InternetExplorerWarning from "./components/default/Routes/InternetExplorerWarning";
import TrackRoute from "./components/default/Routes/TrackRoute";
import ScrollToTop from "./components/default/Routes/ScrollToTop";

// Sample Campaign Components
import CampaignHeader from "./components/3ce/CampaignComponents/sample-campaign/CampaignHeader";

//Actions
import {
    setUser,
    getUserProfile,
    checkAuthentication,
} from "./actions/user_actions";
import { getOrganization } from "./actions/content_actions";
import { setSelectedUserProfile } from "./actions/admin_actions";

// Utils
// import LogRocket from "logrocket";
import { Suspense } from "react";
import { getOrganizationComponent } from "./helpers/functions";
import TaskPageContainer from "./components/default/Routes/TaskPageContainer";

function Routes(props) {
    const { user, userProfile, organization } = props;
    const pathname = useLocation().pathname || null;

    const [OrganizationHeader, setOrganizationHeader] = useState(
        getOrganizationComponent(
            organization.data != null && organization.data.organization,
            "Header"
        )
    );
    const [headerSet, setHeaderSet] = useState(false);
    const [open, toggleDrawer] = useState(false);

    // useEffect(() => {
    //     window.nativeForms.form = 'https://surveys.electrifyze.com/wMv9mcQ1jZmgnZzkEcL1Db/'
    //     LogRocket.getSessionURL(sessionURL => {
    //         window.nativeForms.session_url = sessionURL
    //     });
    // }, [])

    useEffect(() => {
        props.setSelectedUserProfile(null);
        if (open) {
            toggleDrawer(!open);
        }
    }, [pathname]);

    useEffect(() => {
        if (
            !headerSet &&
            (organization.data != null || userProfile.data != null)
        ) {
            setHeaderSet(true);
            setOrganizationHeader(
                getOrganizationComponent(
                    (organization.data != null &&
                        organization.data.organization) ||
                        (userProfile.data != null &&
                            (userProfile.data.organization.name ||
                                userProfile.data.organization)),
                    "Header"
                )
            );
        }
    }, [headerSet, organization, userProfile]);

    useEffect(() => {
        if (user.data !== null && userProfile.data !== null) {
            // window.nativeForms.name = userProfile.data.first_name + " " + userProfile.data.last_name
            // window.nativeForms.email = userProfile.data.email

            // This is an example script - don't forget to change it!
            // LogRocket.identify(user.data.uid, {
            //     name:
            //         userProfile.data.first_name +
            //         " " +
            //         userProfile.data.last_name,
            //     email: userProfile.data.email,

            //     // Add your own custom user variables here, ie:
            //     organization:
            //         userProfile.data.currentOrganization ||
            //         userProfile.data.organization.name ||
            //         userProfile.data.organization,
            // });
        }
    }, [user.data, userProfile.data]);

    const handleDrawerToggle = () => {
        toggleDrawer(!open);
    };

    const isCampaignPath = () => {
        const campaignRoutes = [
            "/pre-register",
            "/campaign",
            "/campaign-launch",
        ];
        const campaigns = [
            "/earth_day",
            "/2021-independence",
            "/2021-rideshare",
            "/2022-lac-earthday",
            "/2022-3ce-earthday",
            "/2022-lac-independence",
            "/2022-3ce-independence",
            "/2022-lac-rideshare",
            "/lac-etc-training-1-0",
            "/2023-lac-earthday",
            "/2023-lac-rideshare",
            "/2024-lac-earthday"
        ];

        let any = false;
        campaigns.forEach((campaign) => {
            campaignRoutes.forEach((route) => {
                if (pathname.startsWith(campaign + route)) {
                    any = true;
                }
            });
        });

        // Hide header for all /sample-campaign pages
        // if (pathname.startsWith('/sample-campaign')) {
        //     any = true
        // }

        return any;
    };

    return (
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                /* mobile viewport bug fix */
                // minHeight: '-webkit-fill-available'
            }}
        >
            <TrackRoute />
            <ScrollToTop />
            <AlertDialog />
            <InternetExplorerWarning />

            <Suspense fallback={isCampaignPath() ? <></> : <Header />}>
                {
                    // This is an example of how to conditionally show or hide a header component based upon URL path.
                    pathname.startsWith("/sample-campaign") ? (
                        <CampaignHeader />
                    ) : (
                        <OrganizationHeader
                            open={open}
                            toggleDrawer={handleDrawerToggle}
                        />
                    )
                }
            </Suspense>

            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/campaigns/:id">
                    <TaskPageContainer>
                        <Task />
                    </TaskPageContainer>
                </Route>
                <Route path="/:campaign/task/:id">
                    <TaskPageContainer>
                        <Task />
                    </TaskPageContainer>
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/password_reset">
                    <PasswordReset />
                </Route>

                <PrivateRoute
                    path="/surveyComplete"
                    component={<SurveyComplete />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/admin"
                    component={<Admin />}
                    type="admin"
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/past_campaigns"
                    component={<PastCampaigns />}
                    type="admin"
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/dashboard"
                    component={<Dashboard />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/home"
                    component={<AuthenticatedHome />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/ev_learning"
                    component={<LearnAboutEV />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/choose_ev"
                    component={<ChooseEV />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/:campaign_key/welcome"
                    component={<AuthenticatedCampaign />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/contests"
                    component={<Contests />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/courses"
                    component={<Courses />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/sections/:id"
                    component={<Sections />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/task/:id"
                    component={<Task />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/transition"
                    component={<Transition />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/contact"
                    component={<Contact />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/refer"
                    component={<Refer />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/coaching"
                    component={<Coaching />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />
                <PrivateRoute
                    path="/info"
                    component={<AuthenticatedAbout />}
                    open={open}
                    toggleDrawer={handleDrawerToggle}
                />

                <Route path="/:organization/login">
                    <Login />
                </Route>
                <Route path="/:campaign_key/campaign">
                    <Campaign />
                </Route>
                <Route path="/:campaign_key/pre-register">
                    <PreRegister />
                </Route>
                <Route path="/:campaign_key/campaign-launch">
                    <CampaignLaunch />
                </Route>
                <Route path="/:campaign_key/campaign-soon">
                    <Campaign />
                </Route>

                {/* This needs to be at the bottom to check if other routes exist first, if not assume it's an organization */}
                <Route exact path="/:organization">
                    <Home />
                </Route>

                <Redirect from="*" to="/" />
            </Switch>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userProfile: state.user.userProfile,
        organization: state.content.organization,
    };
};

const mapDispatchToProps = {
    setUser,
    getUserProfile,
    checkAuthentication,
    getOrganization,
    setSelectedUserProfile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
