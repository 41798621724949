import React, { useEffect } from "react";
import { connect } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";

//Components
import { StyledTaskButton } from "../Styled Components/Buttons";
import LoginRegistrationDialog from "../../la_county/LoginRegistration";
import EVCoachMessage from "../Login/EVCoachMessage";

// Assets
import EarthCharacter from '../../../static/images/earth-only.png'
import LadyStatuePlugSmile from '../../../static/images/lady-statute-charging-plug-smile.png'
import ISDRideshareLogo from '../../../static/images/isd-rideshare-transparent.png'


const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
    },
    grid: {
        minHeight: "100%",
        maxWidth: 800,
        margin: "0 auto",
    },
    orText: {
        marginTop: 30,
        marginBottom: 8,
    },
    link: {
        textDecoration: "none",
    },
}));

function CampaignSlide(props) {
    //Pass theme styles as props
    const classes = useStyles(props);
    const { singleTask } = props;

    const [visible, toggleModal] = React.useState(false);

    useEffect(() => {
        //We made it to the final save so we have to keep track of task in local storage
        let completedTasks = {
            [singleTask.id]: {
                points: singleTask.points,
                section_id: singleTask.sectionID,
                sub_section_id: singleTask.subSectionID,
                course_id: singleTask.courseID,
                type: "activity",
                time: Date.now(),
            },
        };

        // Make sure to convert to a string
        try {
            window.localStorage.setItem(
                "completedTasks",
                JSON.stringify(completedTasks)
            );
        } catch(e) {
            console.log(e)
        }
    });

    // Determine which character to show
    let coachImage; // No character is defined by default, meaning the EVCoachMessage component its default coach character image
    if (singleTask.courseID === "naDTJAHxaD6GdsEv9gaj") { // Earth Day demo campaign for 3CE
        coachImage = EarthCharacter;
    } else if (singleTask.courseID === "SMjr20wuo516hlhvJvKv") { // 2022-3ce-earthday
        coachImage = EarthCharacter;
    } else if (singleTask.courseID === "LBAih7yvjrI6tkCRCERJ") { // 2022-lac-earthday
        coachImage = EarthCharacter;
    } else if (singleTask.courseID === "ZLlUj8l82hFEO4wHkvoN") { // 2022-lac-independence
        coachImage = LadyStatuePlugSmile;
    } else if (singleTask.courseID === "0DdJkIK073oVR6Y8iEN0") { // 2022-3ce-independence
        coachImage = LadyStatuePlugSmile;
    } else if (singleTask.courseID === "W2Si2qJRCicGm4VuzXaX") { // 2022-lac-rideshare
        coachImage = ISDRideshareLogo;
    } else if (singleTask.courseID === "pjaCg8xiMHNxhmAsdjla") { // lac-etc-training-1-0
        coachImage = ISDRideshareLogo;
    } else if (singleTask.courseID === "AS4SRYKfo2M0vaEsafRq") { // 2023-lac-earthday
        coachImage = EarthCharacter;
    } else if (singleTask.courseID === "POK19FUOtIkmyom8wKxm") { // 2024-lac-earthday
        coachImage = EarthCharacter;
    } else if (singleTask.courseID === "AiPIJP02tjNPBOoQliyr") { // 2023-lac-rideshare
        coachImage = ISDRideshareLogo;
    }
    

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.grid}
            >
                <EVCoachMessage
                    coachImage={coachImage}
                    message={
                        <div>
                            <b>Congratulations</b> on completing your first
                            activity. You’ve earned {singleTask.points} points!
                            <br />
                            <br />
                            Login to redeem your points and keep going...
                        </div>
                    }
                />

                <StyledTaskButton
                    onClick={() => toggleModal(true)}
                    style={{ marginTop: 20 }}
                >
                    Get Started
                </StyledTaskButton>

                {visible && (
                    <LoginRegistrationDialog
                        visible={visible}
                        handleClose={() => toggleModal(false)}
                        singleTask={singleTask}
                    />
                )}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSlide);
